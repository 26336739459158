import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['quizTeen1', 'quizTeen2', 'quizTeen3', 'quizTeen4', 'quizTeen5'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paper = makeShortcode("Paper");
const Quiz = makeShortcode("Quiz");
const GoTo = makeShortcode("GoTo");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "how-well-do-you-know-your-teenager"
    }}>{`How well do you know your teenager?`}</h1>
    <p>{`Here is an exercise to help you get to know your teenager's world better.`}</p>
    <Paper mdxType="Paper">
  <Quiz id="quizTeen1" once={true} inline={true} question={<b>
        Do you know what your teenager enjoys doing in their spare time (e.g. listening to music, gaming, hanging out
        with friends, kicking the football)?
      </b>} fallbackResponse={<p>
        It's great to know what your teenager is doing in their spare time and to encourage them to do positive
        activities that they enjoy.
      </p>} answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizTeen2" once={true} inline={true} question={<b>
        Do you know what time of day your teenager prefers to spend time with you (e.g. after school, dinner time,
        weekend afternoons)?
      </b>} fallbackResponse={<p>
        Teenagers aren't always ready to talk when you are. Knowing what time of day suits them will help you choose the
        best time to approach them.
      </p>} answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizTeen3" once={true} inline={true} question={<b>Do you know who your teenager's friends are?</b>} fallbackResponse={<p>
        Friends are a huge part of your teenager's life. Knowing who they are and what they do together helps you
        understand their influence on your teenager's life.
      </p>} answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizTeen4" once={true} inline={true} question={<b>Do you know how your teenager is performing at school?</b>} fallbackResponse={<p>
        While it's common for parents to want their teenager to excel at school, it's important to encourage their
        efforts regardless of their results.
      </p>} answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizTeen5" once={true} inline={true} question={<b>Do you know your teenager's Facebook, Instagram, Snapchat, Twitter, and/or Reddit account name?</b>} fallbackResponse={<p>
        Knowing your teenager's cyberworld is just as important as knowing their physical world. Talking to them about
        what they like to do online is a good way to start (see{' '}
        <GoTo to="/dashboard" mdxType="GoTo"><i>Partners in Problem Solving</i></GoTo> module).
      </p>} answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      